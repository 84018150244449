import { hmOrderService } from '@/api';
import { OsTable } from '@/components';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { OsTableOption, OsTableColumn, RowOperation } from '@/components/os-table/os-table';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { PagingMixin } from '@/mixins/paging';
import { HmOrderList, HmOrderListQuery, HmOrderEdit } from '@/resource/model';
import { translation, showWarningConfirm, messageError, dateFormat, downloadFileByBlob } from '@/utils';
import { Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';
import AddHmOrder from './add-hm-order/add-hm-order.vue';

@Component({
  components: {
    AddHmOrder
  }
})
export default class HmOrder extends mixins(PagingMixin, CustomColumnMixin) {
  public $table!: OsTable;

  /**
   * 表单dialog控制
   */
  public dialogVisible = false;

  /**
   * 编辑数据对象
   */
  public editRow: HmOrderList | null = null;

  /**
   * 表格配置项
   */
  public tableOption: OsTableOption<HmOrderList> = {
    loading: false,
    data: [],
    fit: true,
    size: 'small',
    border: true
  };

  /**
   * 查询条件UI配置
   */
  public queryFormOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'DateRangePicker',
      field: 'orderCreateTime',
      label: 'hmOrder.createTime',
      option: {
        rangeSeparator: '~',
        pickerOptions: {
          disabledDate(callbackDateStr: string): Boolean {
            const callbackDateTime = new Date(callbackDateStr).getTime();
            const today = new Date();
            const currentDateTime = today.getTime();
            return callbackDateTime >= currentDateTime;
          }
        }
      }
    }
  ];

  /**
   * 查询表单对象
   */
  public queryForm: Partial<HmOrderListQuery> & {
    orderCreateTime: Array<string>;
  } = {
    keywords: '',
    orderCreateTime: []
  };

  /**
   * 表格工具栏配置
   */
  public tableToolbarOptions: Array<OperationOption> = [
    {
      operationType: 'add',
      slot: 'start',
      label: 'hmOrder.addOrder',
      type: 'primary',
      icon: 'el-icon-plus',
      permissionCode: 'hm:order:save',
      handleClick: this.openCreatePackingDialog
    },
    {
      operationType: 'export',
      slot: 'start',
      label: 'hmOrder.exportShippingDetails',
      type: 'primary',
      icon: 'el-icon-download',
      permissionCode: 'hm:order:exportData',
      loading: false,
      handleClick: this.exportShippingDetails
    }
  ];

  /**
   * 表格列配置
   */
  public defaultColumnOptions: Array<OsTableColumn<HmOrderList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: 'left'
    },
    {
      prop: 'orderName',
      label: 'hmOrder.orderName',
      minWidth: '180px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'campaignNo',
      label: 'hmOrder.campaignNo',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'campaignName',
      label: 'hmOrder.campaignName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'projectCode',
      label: 'hmOrder.projectCode',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'area',
      label: 'hmOrder.area',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'version',
      label: 'hmOrder.version',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'sendDate',
      label: 'hmOrder.sendDate',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'onSiteDate',
      label: 'hmOrder.onSiteDate',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'transportDate',
      label: 'hmOrder.transportDate',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'logisticsServiceProvider',
      label: 'hmOrder.logisticsServiceProvider',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'invoiceIssuingAddress',
      label: 'hmOrder.invoiceIssuingAddress',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'invoiceReference',
      label: 'hmOrder.invoiceReference',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'specCode',
      label: 'hmOrder.specCode',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'comments',
      label: 'hmOrder.comments',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'hmOrder.createTime',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        return dateFormat((row as HmOrderList).createTime);
      }
    },
    {
      prop: 'createUserName',
      label: 'hmOrder.createUserName',
      minWidth: '100px',
      showOverflowTooltip: true
    }
  ];

  /**
   * 表格行操作配置
   */
  public rowOperationOptions: RowOperation<HmOrderList> = {
    fixed: 'right',
    width: '400px',
    operations: [
      {
        operationType: 'downloadPSL',
        type: 'text',
        label: 'hmOrder.downloadPSL',
        icon: 'el-icon-download',
        size: 'small',
        permissionCode: 'hm:order:exportData',
        handleClick: (row: HmOrderList): void => {
          this.downloadPSL(row);
        }
      },
      {
        operationType: 'downloadBoxSticker',
        type: 'text',
        label: 'hmOrder.downloadBoxSticker',
        icon: 'el-icon-download',
        size: 'small',
        permissionCode: 'hm:order:exportData',
        handleClick: (row: HmOrderList): void => {
          this.downloadBoxSticker(row);
        }
      },
      {
        operationType: 'downloadPalletSticker',
        type: 'text',
        label: 'hmOrder.downloadPalletSticker',
        icon: 'el-icon-download',
        size: 'small',
        permissionCode: 'hm:order:exportData',
        handleClick: (row: HmOrderList): void => {
          this.downloadPalletSticker(row);
        }
      },
      {
        operationType: 'downloadProductTag',
        type: 'text',
        label: 'hmOrder.downloadProductTag',
        icon: 'el-icon-download',
        size: 'small',
        permissionCode: 'hm:order:exportData',
        handleClick: (row: HmOrderList): void => {
          this.downloadProductTag(row);
        }
      },
      {
        operationType: 'downloadBooking',
        type: 'text',
        label: 'hmOrder.downloadBooking',
        icon: 'el-icon-download',
        size: 'small',
        permissionCode: 'hm:order:exportData',
        handleClick: (row: HmOrderList): void => {
          this.downloadBooking(row);
        }
      },
      {
        operationType: 'delete',
        type: 'text',
        label: 'button.delete',
        icon: 'el-icon-delete',
        size: 'small',
        permissionCode: 'hm:order:delete',
        handleClick: (row: HmOrderList): void => {
          // 删除
          this.delete(row.id);
        }
      }
    ]
  };

  private selectedRows: Array<HmOrderList> = [];

  public handleSelectionChange(selectedData: Array<HmOrderList>): void {
    this.selectedRows = selectedData;
  }

  /**
   * 打开新建窗口
   */
  public openCreatePackingDialog(): void {
    this.dialogVisible = true;
  }

  public async downloadPSL(data: HmOrderList): Promise<void> {
    this.tableOption.loading = true;
    try {
      const blob = await hmOrderService.downloadPSL(data.id);
      downloadFileByBlob(`${data.orderNumber}_${translation('hmOrder.downloadPSL')}.xlsx`, blob);
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  public async downloadBoxSticker(data: HmOrderList): Promise<void> {
    this.tableOption.loading = true;
    try {
      const blob = await hmOrderService.downloadBoxSticker(data.id);
      downloadFileByBlob(`${data.orderNumber}_${translation('hmOrder.downloadBoxSticker')}.xlsx`, blob);
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  public async downloadPalletSticker(data: HmOrderList): Promise<void> {
    this.tableOption.loading = true;
    try {
      const blob = await hmOrderService.downloadPalletSticker(data.id);
      downloadFileByBlob(`${data.orderNumber}_${translation('hmOrder.downloadPalletSticker')}.xlsx`, blob);
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  public async downloadProductTag(data: HmOrderList): Promise<void> {
    this.tableOption.loading = true;
    try {
      const blob = await hmOrderService.downloadProductTag(data.id);
      downloadFileByBlob(`${data.orderNumber}_${translation('hmOrder.downloadProductTag')}.xlsx`, blob);
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  public async downloadBooking(data: HmOrderList): Promise<void> {
    this.tableOption.loading = true;
    try {
      const blob = await hmOrderService.downloadBooking(data.id);
      downloadFileByBlob(`${data.orderNumber}_${translation('hmOrder.downloadBooking')}.xlsx`, blob);
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  public dialogClosed(): void {
    this.editRow = null;
  }

  public editSuccess(data: HmOrderEdit): void {
    const findItem = this.tableOption.data.find(x => x.id === data.id);
    Object.assign(findItem, data);
  }

  /**
   * 删除
   */
  public delete(id: number): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          await hmOrderService.delete(id);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    this.selectedRows = [];
    this.loadData();
  }

  public loadData(): void {
    this.$table.openLoading();

    hmOrderService
      .getList(this.queryForm, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.$table.closeLoading();
      });
  }

  public created(): void {
    this.initColumns(this.defaultColumnOptions, 'hm-order');
  }

  public mounted(): void {
    this.$table = this.$refs.hmOrderTable as OsTable;
    this.loadData();
  }

  /**
   * 分页切换回调
   */
  public pagingData(): void {
    this.loadData();
  }

  @Watch('queryForm.orderCreateTime')
  private handleCreateTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.startCreateTime = undefined;
      this.queryForm.endCreateTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.startCreateTime = dateFormat(value[0]);
      this.queryForm.endCreateTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  /**
   * 导出发货明细
   */
  private async exportShippingDetails(): Promise<void> {
    const exportBtn = this.tableToolbarOptions.find(x => x.operationType === 'export');
    exportBtn!.loading = true;
    try {
      const blob = await hmOrderService.exportShippingDetails({
        ...this.queryForm,
        idList: this.selectedRows.map(x => x.id)
      });
      downloadFileByBlob(`${translation('hmOrder.shippingDetailsFileName')}_${dateFormat(new Date())}.xlsx`, blob);
    } catch (error) {
      messageError(error);
    } finally {
      exportBtn!.loading = false;
    }
  }
}

import { hmOrderService, minioService } from '@/api';
import { MinIOFile } from '@/api/minio';
import OsFileUpload, { OsFileUploadOptions } from '@/components/os-file-upload/os-file-upload';
import { DialogMixin } from '@/mixins/dialog';
import { HmOrderEdit } from '@/resource/model';
import { translation, messageError, dateFormat } from '@/utils';
import { Form, Message } from 'element-ui';
import { HttpRequestOptions } from 'element-ui/types/upload';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class AddHmOrder extends mixins(DialogMixin) {
  @Prop({
    required: false,
    type: Object,
    default: () => {
      return null;
    }
  })
  public order!: HmOrderEdit | null;

  public orderForm: HmOrderEdit = {
    id: null,
    file: '',
    orderName: ''
  };

  public fileUploadOptions: OsFileUploadOptions = {
    maxSize: 20,
    accept: '.xlsx, .xls',
    autoUpload: true,
    limit: 1,
    multiple: false,
    drag: true,
    action: '',
    uploadLoading: false,
    httpRequest: this.uploadFile
  };

  public orderFormRules = {
    orderName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value || value.length === 0) {
            callback(new Error(translation('HmOrder.inputOrderName')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    file: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value || value.length === 0) {
            callback(new Error(translation('HmOrder.uploadFile')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };

  public submitLoading = false;
  private operationType: 'add' | 'edit' = 'add';

  public dialogOpen(): void {
    if (!this.order) {
      this.operationType = 'add';
      this.title = 'hmOrder.addOrder';
      return;
    }
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.fileUpload as OsFileUpload).clearFiles();
    (this.$refs.orderForm as Form).resetFields();
  }

  public onSubmit(): void {
    (this.$refs.orderForm as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        if (this.operationType === 'add') {
          await hmOrderService.post({ ...this.orderForm } as any);
          Message.success(translation('operationRes.addSuccess'));
          this.$emit('add-success');
          this.closeDialog();
          return;
        }
        await hmOrderService.put({ ...this.orderForm } as any);
        Message.success(translation('operationRes.editSuccess'));
        this.$emit('edit-success', this.orderForm);
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }

  private async uploadFile(requestOptions: HttpRequestOptions): Promise<void> {
    try {
      const minioFile: MinIOFile = {
        name: `${dateFormat(new Date(), 'yyyyMMDDHHmmss')}_${requestOptions.file.name}`,
        stream: Buffer.from(await requestOptions.file.arrayBuffer()),
        metadata: { 'Content-Type': requestOptions.file.type },
        size: requestOptions.file.size
      };
      const filePath = `import/hm/order`;

      await minioService.init();

      await minioService.uploadByStream(minioFile, filePath);

      this.orderForm.file = await minioService.getDownLoadPath(`${filePath}/${minioFile.name}`);
      this.orderForm.orderName = requestOptions.file.name;
    } catch (error) {
      messageError(error);
    }
  }
}
